import React, { useContext, useEffect, useMemo, useRef } from 'react';
import {
  GroupIcon,
  Note2Icon,
  NoteIcon,
  SettingIcon,
  ToolIcon
} from 'src/assets/icons';
import { RoutePath } from 'src/enums/routePath';
import { unSlash } from 'src/utils/route';
import { Menu, MenuProps, styled } from 'core-ui-2';
import { Button } from 'core-ui';
import { useLocation, useNavigate } from 'react-router-dom';
import { isArray } from 'lodash';
import { ProfileContext } from 'src/contexts/profile';
import { PermissionList } from 'src/enums/permission';

import {
  getListDontHighLightMenu,
  removeSearchPage,
  setListDontHighLightMenu
} from 'src/utils/common';

const StyledMenu = styled(Menu)`
  border-inline-end: none !important;

  .ant-menu-submenu {
    .ant-menu-submenu-title {
      margin-inline: 0px;
      margin-block: 0px;
      width: 100%;
      border-radius: 0px;
      color: #8c8c8c;
      font-weight: 600;
    }
  }

  .ant-menu-item {
    margin-inline: 0px;
    margin-block: 0px;
    width: 100%;
    border-radius: 0px;
    color: #8c8c8c;
    font-weight: 600;

    &.ant-menu-item-selected {
      position: relative;
      background-color: rgba(82, 114, 189, 0.15);
      color: #5272bd;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        border-left: 3px solid #5272bd !important;
      }
    }
  }
`;

const SideMenu: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { hasRole, hasAnyOfRoles, fetchProfile } = useContext(ProfileContext);
  useMemo(() => {
    removeSearchPage();
    setListDontHighLightMenu('', true, true);
  }, []);

  const menuItems: MenuProps['items'] = [
    {
      key: unSlash(RoutePath.ADMIN) + RoutePath.INSURANCE_APPLICATION,
      icon: React.createElement(Note2Icon),
      label: '修理同意書',
      style: {
        display: hasRole(PermissionList.SUBMIT_AGREEMENT_LIST) ? 'flex' : 'none'
      }
    },
    {
      key: 'customers',
      icon: React.createElement(GroupIcon),
      label: '顧客',
      style: {
        display: hasAnyOfRoles([
          PermissionList.CUSTOMER_LIST,
          PermissionList.CLIENT_LIST
        ])
          ? ''
          : 'none'
      },
      children: [
        {
          key: unSlash(RoutePath.ADMIN) + RoutePath.CLIENTS,
          label: '顧客',
          style: {
            display: hasRole(PermissionList.CLIENT_LIST) ? 'flex' : 'none'
          }
        },
        {
          key: unSlash(RoutePath.ADMIN) + RoutePath.CUSTOMERS,
          label: '会員',
          style: {
            display: hasRole(PermissionList.CUSTOMER_LIST) ? 'flex' : 'none'
          }
        }
      ]
    },
    {
      key: unSlash(RoutePath.REPAIRS),
      icon: React.createElement(ToolIcon),
      label: '修理',
      style: {
        display: hasRole(PermissionList.REPAIR_LIST) ? 'flex' : 'none'
      }
    },
    {
      key: unSlash(RoutePath.ADMIN) + RoutePath.ACCOUNT,
      icon: React.createElement(NoteIcon),
      label: 'アカウント',
      style: {
        display: hasRole(PermissionList.ACCOUNT_LIST) ? 'flex' : 'none'
      }
    },
    {
      key: '',
      icon: React.createElement(SettingIcon),
      label: '設定',
      style: {
        display: hasAnyOfRoles([
          PermissionList.PERMISSION_LIST,
          PermissionList.ENABLING_REPAIR_AGREEMENT_LINK,
          PermissionList.MAKER_LIST,
          PermissionList.MODEL_LIST,
          PermissionList.CATEGORY_LIST
        ])
          ? ''
          : 'none'
      },
      children: [
        {
          key: unSlash(RoutePath.ADMIN) + RoutePath.CONFIG,
          label: '権限設定',
          style: {
            display: hasRole(PermissionList.PERMISSION_LIST) ? 'flex' : 'none'
          }
        },
        {
          key:
            unSlash(RoutePath.ADMIN) + RoutePath.CONFIG + RoutePath.EXPIRATION,
          label: '全般設定',
          style: {
            display: hasRole(PermissionList.ENABLING_REPAIR_AGREEMENT_LINK)
              ? 'flex'
              : 'none'
          }
        },
        {
          key: unSlash(RoutePath.ADMIN) + RoutePath.MAKERS,
          label: 'メーカー名マスター',
          style: {
            display: hasRole(PermissionList.MAKER_LIST) ? 'flex' : 'none'
          }
        },
        {
          key: unSlash(RoutePath.ADMIN) + RoutePath.MODELS,
          label: '型番マスター',
          style: {
            display: hasRole(PermissionList.MODEL_LIST) ? 'flex' : 'none'
          }
        },
        {
          key: unSlash(RoutePath.ADMIN) + RoutePath.PRODUCTS,
          label: '製品カテゴリーマスター',
          style: {
            display: hasRole(PermissionList.CATEGORY_LIST) ? 'flex' : 'none'
          }
        }
      ]
    }
  ];

  const collectKey = (
    menuItems: MenuProps['items'],
    result: { [key: string]: string }
  ) => {
    menuItems?.forEach((item) => {
      const key = item?.key?.toString() || '';
      result[key] = key;
      const parseItem = item as any;
      if (isArray(parseItem?.children)) {
        collectKey(parseItem?.children, result);
      }
    });
    return result;
  };

  const patternKeys = useMemo(() => {
    const result: { [key: string]: string } = {};
    collectKey(menuItems, result);
    return result;
  }, [menuItems]);

  const arrayPathname = location.pathname.split('/');
  let key = '';

  const listDontHighLight = getListDontHighLightMenu();
  arrayPathname.reverse().forEach((_, index) => {
    const keyPath = [...arrayPathname].splice(index, arrayPathname.length);
    const keyString = keyPath
      .reverse()
      .join('/')
      .replace('/', '') as keyof typeof patternKeys;
    if (
      listDontHighLight[keyString] &&
      keyString != location.pathname.replace('/', '')
    )
      return '';

    if (patternKeys[keyString] && !key) key = patternKeys[keyString];
  });
  //const dataParams = useParams()

  useEffect(() => {
    // fix bug https://redmine.javis.vn/issues/93275 - Sau khi tạo/sửa thông tin, màn hình chuyển về giữa trang danh
    const containerScroll = document.querySelector(
      '#root > div > div > div > div > .ant-layout-content'
    ) as HTMLDivElement;
    const containerScrollOutSide = document.querySelector(
      '#root > div > div > div > div'
    ) as HTMLDivElement;
    if (containerScroll) containerScroll.scrollTop = 0;
    if (containerScrollOutSide) containerScrollOutSide.scrollTop = 0;
  }, [location.pathname]);

  const refPreKey = useRef(key);

  const handleMenuClick: MenuProps['onClick'] = (info) => {
    setListDontHighLightMenu(info.key, false);
    navigate(info.key);
    if (key !== refPreKey.current) {
      removeSearchPage();
      refPreKey.current = key;
    }
  };

  const goToSubmitRepairAgreementPage = () => {
    navigate(RoutePath.ADMIN + RoutePath.INSURANCE_APPLICATION + RoutePath.NEW);
    setListDontHighLightMenu(
      unSlash(RoutePath.ADMIN) + RoutePath.INSURANCE_APPLICATION,
      true
    );
  };
  // useEffect(() => {
  //   fetchProfile?.();
  // }, []);

  return (
    <aside className="h-full relative">
      <div className="mt-2 mb-1 px-2">
        {hasRole(PermissionList.SUBMIT_AGREEMENT_CREATE) && (
          <Button
            type="primary"
            className="rounded-lg font-bold border-[#215493] h-10 !w-[196px]"
            onClick={goToSubmitRepairAgreementPage}
          >
            修理同意送信
          </Button>
        )}
      </div>
      <StyledMenu
        mode="inline"
        items={menuItems}
        onClick={handleMenuClick}
        selectedKeys={[key]}
      />
    </aside>
  );
};

export default SideMenu;
