import { RoutePath } from 'src/enums/routePath';

export const unSlash = (route: RoutePath) => {
  return route.substring(1);
};

export const getFallbackPath = (pathname: string) => {
  return pathname.startsWith('/admin')
    ? RoutePath.ADMIN + RoutePath.LOGIN
    : RoutePath.CUSTOMER + RoutePath.LOGIN;
};
