import {
  AnyAction,
  CaseReducer,
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import ReduxSliceNames from 'src/constants/redux-slice-names';
import { QueryParams } from 'src/models/apiRequest';
import { IManufacturer } from 'src/models/insurance';
import { getPublicManufacturerList } from 'src/services/public/insurance';

interface IManufacturerState {
  manufacturers: IManufacturer[];
}

export const fetchManufacturers = createAsyncThunk(
  ReduxSliceNames.ManufacturersFetchList,
  async (query: QueryParams) => {
    const { data } = await getPublicManufacturerList(query);
    return data;
  }
);

const initialState: IManufacturerState = {
  manufacturers: []
};

const fetchManufacturersReducer: CaseReducer = (
  state: IManufacturerState,
  action: AnyAction
) => {
  state.manufacturers = action.payload;
};

const manufacturerSlice = createSlice({
  name: ReduxSliceNames.Manufacturers,
  initialState,
  reducers: {
    resetManufacturersState(state: IManufacturerState) {
      state.manufacturers = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchManufacturers.fulfilled, fetchManufacturersReducer);
  }
});

export const { resetManufacturersState } = manufacturerSlice.actions;

export default manufacturerSlice;
