import React, { useEffect } from 'react';
import { CalendarIcon, Note2Icon, UserIcon } from 'src/assets/icons';
import { RoutePath } from 'src/enums/routePath';
import { unSlash } from 'src/utils/route';
import { Menu, MenuProps, styled } from 'core-ui-2';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'src/redux/store';
import customerAgreementSlice, {
  fetchNotReadAgreementCount
} from 'src/redux/customerAgreement/customerAgreementSlice';
import { useSelectedKey } from 'src/hooks/useSelectedKey';

const StyledMenu = styled(Menu)`
  border-inline-end: none !important;

  .ant-menu-submenu {
    .ant-menu-submenu-title {
      margin-inline: 0px;
      margin-block: 0px;
      width: 100%;
      border-radius: 0px;
      color: #8c8c8c;
      font-weight: 600;
    }
  }

  .ant-menu-item {
    margin-inline: 0px;
    margin-block: 0px;
    width: 100%;
    border-radius: 0px;
    color: #8c8c8c;
    font-weight: 600;

    &.ant-menu-item-selected {
      position: relative;
      background-color: rgba(82, 114, 189, 0.15);
      color: #5272bd;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        border-left: 3px solid #5272bd !important;
      }
    }
  }
`;

const RenderedAgreementMenuIcon = ({ hasNoti }: { hasNoti: boolean }) => {
  if (hasNoti) {
    return (
      <div className="relative">
        <Note2Icon />
        <span className="bg-[#D83535] w-3 h-3 rounded-lg absolute -top-1 right-1"></span>
      </div>
    );
  }
  return <Note2Icon />;
};

const SideMenu: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(fetchNotReadAgreementCount());
  }, []);

  const { notReadCount } = useSelector(
    (state: RootState) => state[customerAgreementSlice.name]
  );

  const menuItems: MenuProps['items'] = [
    {
      key: unSlash(RoutePath.CUSTOMER) + RoutePath.INSURANCE_APPLICATION,
      icon: React.createElement(RenderedAgreementMenuIcon, {
        hasNoti: notReadCount > 0
      }),
      label: '修理同意送信'
    },
    {
      key: unSlash(RoutePath.CUSTOMER_HISTORY),
      icon: React.createElement(CalendarIcon),
      label: '修理履歴'
    },
    {
      key: unSlash(RoutePath.CUSTOMER) + RoutePath.PERSONAL_INFO,
      icon: React.createElement(UserIcon),
      label: '個人情報管理'
    }
  ];

  const key = useSelectedKey(menuItems);

  const handleMenuClick: MenuProps['onClick'] = (info) => {
    navigate(info.key);
  };

  return (
    <aside className="h-full relative pt-2">
      <StyledMenu
        mode="inline"
        items={menuItems}
        onClick={handleMenuClick}
        selectedKeys={[key]}
      />
    </aside>
  );
};

export default SideMenu;
