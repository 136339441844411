const Logo = () => {
  return (
    <div className="flex items-center flex-nowrap overflow-x-hidden gap-x-[10px] justify-center h-[100%] bg-primary">
      <span className="text-[13px] font-semibold">
        製品機器保証サービス 管理画面
      </span>
    </div>
  );
};

export default Logo;
