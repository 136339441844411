import Cookies from 'js-cookie';
import {
  ACCESS_TOKEN_KEY,
  CUSTOMER_ACCESS_TOKEN_KEY
} from 'src/constants/auth';

export const isAdminLoggined = () => {
  return !!Cookies.get(ACCESS_TOKEN_KEY);
};

export const isCustomerLoggined = () => {
  return !!Cookies.get(CUSTOMER_ACCESS_TOKEN_KEY);
};

export const getIsLoggined = () => {
  return isAdminLoggined() || isCustomerLoggined();
};
