import {
  AnyAction,
  CaseReducer,
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import ReduxSliceNames from 'src/constants/redux-slice-names';
import { IProductCategory, IProductCategoryQuery } from 'src/models/insurance';
import { getPublicProductCategoryList } from 'src/services/public/insurance';

interface IProductCategoryState {
  productCategories: IProductCategory[];
}

export const fetchProducCategories = createAsyncThunk(
  ReduxSliceNames.ProductCategoriesFetchList,
  async (query: IProductCategoryQuery) => {
    const { data } = await getPublicProductCategoryList(query);
    return data;
  }
);

const initialState: IProductCategoryState = {
  productCategories: []
};

const fetchProducCategoriesReducer: CaseReducer = (
  state: IProductCategoryState,
  action: AnyAction
) => {
  state.productCategories = action.payload;
};

const productCategorySlice = createSlice({
  name: ReduxSliceNames.ProductCategories,
  initialState,
  reducers: {
    resetProductCategoryState(state: IProductCategoryState) {
      state.productCategories = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchProducCategories.fulfilled,
      fetchProducCategoriesReducer
    );
  }
});

export const { resetProductCategoryState } = productCategorySlice.actions;

export default productCategorySlice;
