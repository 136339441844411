import {
  AnyAction,
  CaseReducer,
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import ReduxSliceNames from 'src/constants/redux-slice-names';
import { IProductModel, IProductModelQuery } from 'src/models/insurance';
import { getPublicProductModelList } from 'src/services/public/insurance';

interface IProductModelState {
  productModels: IProductModel[];
}

export const fetchProducModels = createAsyncThunk(
  ReduxSliceNames.ProductModelsFetchList,
  async (query: IProductModelQuery) => {
    const { data } = await getPublicProductModelList(query);
    return data;
  }
);

const initialState: IProductModelState = {
  productModels: []
};

const fetchManufacturersReducer: CaseReducer = (
  state: IProductModelState,
  action: AnyAction
) => {
  state.productModels = action.payload;
};

const productModelSlice = createSlice({
  name: ReduxSliceNames.ProductModels,
  initialState,
  reducers: {
    resetProductModelState(state: IProductModelState) {
      state.productModels = [];
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProducModels.fulfilled, fetchManufacturersReducer);
  }
});

export const { resetProductModelState } = productModelSlice.actions;

export default productModelSlice;
