import Cookies from 'js-cookie';
import {
  LoginFormI,
  RegisterFormI,
  ResetPasswordI,
  UpdatePasswordI
} from 'src/models/auth';
import { apiRequest, customerApiRequest } from 'src/utils/api';
import { jwtDecode } from 'jwt-decode';
import { timestampToDate } from 'src/utils/date';
import { RoutePath } from 'src/enums/routePath';
import { ApiPath } from 'src/enums/apiPath';
import {
  ACCESS_TOKEN_KEY,
  CUSTOMER_ACCESS_TOKEN_KEY
} from 'src/constants/auth';

export const login = async (payload: LoginFormI) => {
  const res = await apiRequest.post({
    url: ApiPath.LOGIN,
    data: payload
  });

  const accessToken = res?.data?.data?.access_token;
  const decoded = jwtDecode(accessToken);
  const expiredDate = timestampToDate(Number(decoded?.exp));

  if (!!accessToken) {
    Cookies.set(ACCESS_TOKEN_KEY, accessToken, {
      expires: expiredDate
    });
  }

  return res;
};

export const register = async (payload: RegisterFormI) => {
  return await apiRequest.post({
    url: ApiPath.REGISTER,
    data: payload
  });
};

export const logout = () => {
  Cookies.remove(ACCESS_TOKEN_KEY);
  window.location.replace(RoutePath.LOGIN);
};

export const resetPassword = async (data: ResetPasswordI) => {
  return apiRequest.post({
    url: ApiPath.RESET_PASSWORD,
    data
  });
};

export const updatePassword = async (data: UpdatePasswordI) => {
  return apiRequest.put({
    url: ApiPath.UPDATE_PASSWORD,
    data
  });
};

export const customerLogin = async (payload: LoginFormI) => {
  const res = await customerApiRequest.post({
    url: ApiPath.CUSTOMER_LOGIN,
    data: payload
  });

  const accessToken = res?.data?.data?.access_token;
  const decoded = jwtDecode(accessToken);
  const expiredDate = timestampToDate(Number(decoded?.exp));

  if (!!accessToken) {
    Cookies.set(CUSTOMER_ACCESS_TOKEN_KEY, accessToken, {
      expires: expiredDate
    });
  }

  return res;
};

export const handleCustomerLogout = () => {
  Cookies.remove(CUSTOMER_ACCESS_TOKEN_KEY);
  window.location.replace(RoutePath.CUSTOMER + RoutePath.LOGIN);
};
