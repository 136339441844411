import {
  AnyAction,
  CaseReducer,
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import ReduxSliceNames from 'src/constants/redux-slice-names';
import { ICustomerProfile } from 'src/models/customer';
import { getCustomerProfile } from 'src/services/customer/profile';

export const fetchCustomerProfile = createAsyncThunk(
  ReduxSliceNames.CustomerProfileFetchData,
  async () => {
    const { data } = await getCustomerProfile();
    return data;
  }
);

interface ICustomerProfileState {
  customer: ICustomerProfile | null;
}

const initialState: ICustomerProfileState = {
  customer: {
    id: null,
    memberCode: '',
    name: '',
    nameKana: '',
    zipCode: '',
    address: '',
    phone: '',
    email: '',
    avatarUrl: '',
    company: ''
  }
};

const fetchCustomerProfileReducer: CaseReducer = (
  state: ICustomerProfileState,
  action: AnyAction
) => {
  state.customer = action.payload;
};

const customerProfileSlice = createSlice({
  name: ReduxSliceNames.CustomerProfile,
  initialState,
  reducers: {
    resetCustomerProfile(state: ICustomerProfileState) {
      state.customer = initialState.customer;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchCustomerProfile.fulfilled,
      fetchCustomerProfileReducer
    );
  }
});

export const { resetCustomerProfile } = customerProfileSlice.actions;

export default customerProfileSlice;
