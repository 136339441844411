import NotFound from 'src/pages/NotFound';
import { ZIP_CODE_REGEX } from './regex';

export const notifyMessage = {
  success: 'ログインに成功しました。',
  failed: '失敗した!'
};

export const RequireMessage = {
  EMAIL: 'Eメールは必須です。',
  PASSWORD: 'パスワードは必須です。',
  ZIP_CODE: '郵便番号が必須です。'
};

export const ErrorMessage = {
  EMAIL_OR_PASSWORD_WRONG: 'Eメールまたはパスワードが正しくありません。'
};

export const SuccessMessage = {
  CUSTOMER_LOGIN: 'ログインに成功しました。'
};

export const InvalidMessage = {
  COMMON: 'このフィールドは無効です。',
  EMAIL: 'Eメールは無効です。',
  ZIP_CODE: '無効な郵便番号です。',
  PHONE_JAPAN_NUMBER: '電話番号が無効です。',
  EMAIL_FORMAT: 'メールが無効です。'
};

export const NotFoundMessage = {
  MEMBER_CODE: '会員番号は存在しません。'
};

export const WarningMessage = {
  ZIP_CODE: '郵便番号が存在しません。'
};

export const ResponseMessage = {
  CUSTOMER_LOGIN: 'email_or_password_mismatch'
};

export const FileErrorMessage = {
  LIMIT_FILE:
    '画像ファイルのサイズは50MBを超えているため、アップロードできませんでした。'
};
export const IS_REQUIRED = 'は必須です。';
