import { customerApiRequest } from 'src/utils/api';
import { ApiPath } from 'src/enums/apiPath';

export const getCustomerProfile = async () => {
  const res = await customerApiRequest.get({
    url: ApiPath.CUSTOMER_GET_ME
  });

  return res.data;
};

export const updateAvatar = async (id: string | number, data: FormData) => {
  const res = await customerApiRequest.put({
    url: ApiPath.CUSTOMER_AVATAR + `/${id}`,
    data,
    headers: { 'Content-Type': 'multipart/form-data', 'accept-language': 'ja' }
  });

  return res.data;
};
