import { ThemeConfig } from 'core-ui';

export const systemColor = {
  green: '#618264',
  greenLight: '#e3efe4',
  orangeLight: '#FAAD14',
  black: '#000000',
  white: '#FFFFFF',
  yellow: '#FEAB35',
  successLight: '#51FD00',
  gray: '#F2F2F2',
  blue: '#4759FF',
  blueLight: '#f0f1fb'
};

const theme: ThemeConfig = {
  token: {
    colorPrimary: '#5272BD',
    borderRadius: 2,
    colorText: '#000000',
    fontSizeLG: 14
  },
  components: {
    Menu: {
      itemMarginInline: 0,
      itemMarginBlock: 0,
      itemBorderRadius: 0,
      itemSelectedBg: systemColor.blueLight,
      activeBarWidth: 3,
      itemActiveBg: systemColor.blueLight,
      itemHeight: 40,
      iconSize: 18,
      itemColor: systemColor.black,
      colorItemBgSelected: systemColor.blueLight
    },
    Table: {
      headerBg: '#dddddd',
      headerBorderRadius: 8
    },
    Select: {
      colorBgContainerDisabled: ' #f5f5f5'
    },
    Pagination: {
      colorPrimary: '#1890FF',
      colorPrimaryHover: '#83c2fc'
    },
    Button: {
      fontWeight: 'bold'
    }
  }
};
export default theme;
