export const timestampToDate = (timestamp: number) => {
  return new Date(timestamp * 1000);
};

export const convertDateString = (date: string) => {
  let resultDate = new Date(date);
  let result = `${resultDate.getFullYear()}/${(resultDate.getMonth() + 1).toString().padStart(2, '0')}/${resultDate.getDate().toString().padStart(2, '0')}`;

  return result;
};
