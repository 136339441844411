import { useContext, useEffect, useMemo } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { PrivateRouteI } from 'src/models/router';
import { getFallbackPath } from 'src/utils/route';
import { isAdminLoggined, isCustomerLoggined } from 'src/utils/auth';
import { AuthRole } from 'src/enums/auth';
import { ProfileContext } from 'src/contexts/profile';
import NotFound from 'src/pages/NotFound';

export const PrivateRoute = ({
  children,
  role,
  requirePermission
}: PrivateRouteI) => {
  const { hasRole, profile, fetchProfile, isProfileLoading } =
    useContext(ProfileContext);

  const isLogined = useMemo(() => {
    if (role === AuthRole.ADMIN) {
      return isAdminLoggined();
    }
    if (role === AuthRole.CUSTOMER) {
      return isCustomerLoggined();
    }
  }, [role]);
  useEffect(() => {
    if (!profile) fetchProfile?.();
  }, [profile]);

  const location = useLocation();
  const fallbackPath = getFallbackPath(location.pathname);
  if (!isLogined) return <Navigate to={fallbackPath} replace />;
  if (role === AuthRole.CUSTOMER) return children;
  if (isProfileLoading) return <></>;
  if (requirePermission && !hasRole(requirePermission))
    return (
      <div className="h-[calc(100vh-60px)] flex justify-center items-center font-extrabold text-[30px]">
        この画面はアクセス権限がありません。
      </div>
    );
  return children;
};
