import MenuIconWrapper from 'src/components/MenuIconWrapper';

const UserIcon = () => {
  return (
    <MenuIconWrapper>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.2805 18.7361C20.7752 17.5392 20.0419 16.4521 19.1216 15.5352C18.204 14.6156 17.117 13.8825 15.9207 13.3762C15.9099 13.3709 15.8992 13.3682 15.8885 13.3628C17.5573 12.1575 18.6421 10.1941 18.6421 7.97892C18.6421 4.30927 15.6689 1.33606 11.9992 1.33606C8.32959 1.33606 5.35637 4.30927 5.35637 7.97892C5.35637 10.1941 6.44119 12.1575 8.10994 13.3655C8.09923 13.3709 8.08852 13.3736 8.0778 13.3789C6.8778 13.8852 5.80102 14.6111 4.87691 15.5378C3.95738 16.4554 3.22424 17.5424 2.71798 18.7387C2.22063 19.91 1.9524 21.1656 1.9278 22.4378C1.92709 22.4664 1.9321 22.4949 1.94255 22.5215C1.953 22.5481 1.96867 22.5724 1.98864 22.5928C2.00861 22.6133 2.03247 22.6296 2.05883 22.6407C2.08518 22.6518 2.11349 22.6575 2.14209 22.6575H3.74923C3.86709 22.6575 3.96084 22.5637 3.96352 22.4486C4.01709 20.3807 4.84744 18.4441 6.3153 16.9762C7.83405 15.4575 9.85102 14.6218 11.9992 14.6218C14.1474 14.6218 16.1644 15.4575 17.6832 16.9762C19.151 18.4441 19.9814 20.3807 20.0349 22.4486C20.0376 22.5664 20.1314 22.6575 20.2492 22.6575H21.8564C21.885 22.6575 21.9133 22.6518 21.9396 22.6407C21.966 22.6296 21.9899 22.6133 22.0098 22.5928C22.0298 22.5724 22.0455 22.5481 22.0559 22.5215C22.0664 22.4949 22.0714 22.4664 22.0707 22.4378C22.0439 21.1575 21.7787 19.912 21.2805 18.7361ZM11.9992 12.5861C10.7698 12.5861 9.61262 12.1066 8.74209 11.2361C7.87155 10.3655 7.39209 9.20838 7.39209 7.97892C7.39209 6.74945 7.87155 5.59231 8.74209 4.72177C9.61262 3.85124 10.7698 3.37177 11.9992 3.37177C13.2287 3.37177 14.3858 3.85124 15.2564 4.72177C16.1269 5.59231 16.6064 6.74945 16.6064 7.97892C16.6064 9.20838 16.1269 10.3655 15.2564 11.2361C14.3858 12.1066 13.2287 12.5861 11.9992 12.5861Z"
          fill="currentColor"
        />
      </svg>
    </MenuIconWrapper>
  );
};

export default UserIcon;
