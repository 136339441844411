import ApiRequest from 'core-api';
import { API_URL } from 'src/enums/apiPath';
import { getFallbackPath } from './route';
import {
  ACCESS_TOKEN_KEY,
  CUSTOMER_ACCESS_TOKEN_KEY
} from 'src/constants/auth';
import Cookies from 'js-cookie';

export const apiRequest = new ApiRequest({
  baseUrl: API_URL,
  accessTokenKey: ACCESS_TOKEN_KEY,
  onUnauthenticated: () => {
    const fallBackPath = getFallbackPath(window.location.pathname);
    window.location.replace(fallBackPath);
  }
});

export const publicApiRequest = new ApiRequest({
  baseUrl: API_URL
});

export const customerApiRequest = new ApiRequest({
  baseUrl: API_URL,
  accessTokenKey: CUSTOMER_ACCESS_TOKEN_KEY,
  onUnauthenticated: () => {
    if (!!Cookies.get(CUSTOMER_ACCESS_TOKEN_KEY)) {
      Cookies.remove(CUSTOMER_ACCESS_TOKEN_KEY);
    }
    const fallBackPath = getFallbackPath(window.location.pathname);
    window.location.replace(fallBackPath);
  }
});
