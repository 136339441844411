import { apiRequest } from 'src/utils/api';
import { ApiPath } from 'src/enums/apiPath';

export const getAdminProfile = async () => {
  const res = await apiRequest.get({
    url: ApiPath.ADMIN_GET_ME
  });

  return res.data;
};
