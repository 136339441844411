import React from 'react';
import { Outlet } from 'react-router-dom';

const UserNotLogin = () => {
  return (
    <React.Fragment>
      <header className="p-5 text-center">
        <h1 className="font-bold text-xl">製品機器保証サービス</h1>
      </header>
      <main className="bg-gray-100 min-h-screen sm:py-6">
        <Outlet />
      </main>
    </React.Fragment>
  );
};

export default UserNotLogin;
