import {
  AnyAction,
  CaseReducer,
  createAsyncThunk,
  createSlice
} from '@reduxjs/toolkit';
import ReduxSliceNames from 'src/constants/redux-slice-names';
import { getNotReadAgreementCount } from 'src/services/customer/insurance-agreement';

interface ICustomerAgreementState {
  notReadCount: number;
}

export const fetchNotReadAgreementCount = createAsyncThunk(
  ReduxSliceNames.CustomerAgreementFetchNotReadCount,
  async () => {
    const { data } = await getNotReadAgreementCount();
    return data.count;
  }
);

const initialState: ICustomerAgreementState = {
  notReadCount: 0
};

const fetchNotReadAgreementCountReducer: CaseReducer = (
  state: ICustomerAgreementState,
  action: AnyAction
) => {
  state.notReadCount = action.payload;
};

const customerAgreementSlice = createSlice({
  name: ReduxSliceNames.CustomerAgreement,
  initialState,
  reducers: {
    resetNotReadCountState(state: ICustomerAgreementState) {
      state.notReadCount = 0;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(
      fetchNotReadAgreementCount.fulfilled,
      fetchNotReadAgreementCountReducer
    );
  }
});

export const { resetNotReadCountState } = customerAgreementSlice.actions;

export default customerAgreementSlice;
