import { isArray } from 'lodash';
import { MenuProps } from 'core-ui-2';
import { useLocation, useSearchParams } from 'react-router-dom';
import { useMemo } from 'react';

export const collectKey = (
  menuItems: MenuProps['items'],
  result: { [key: string]: string }
) => {
  menuItems?.forEach((item) => {
    const key = item?.key?.toString() || '';
    result[key] = key;
    const parseItem = item as any;
    if (isArray(parseItem?.children)) {
      collectKey(parseItem?.children, result);
    }
  });
  return result;
};

export const useSelectedKey = (menuItems: MenuProps['items']) => {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  
  const patternKeys = useMemo(() => {
    const result: { [key: string]: string } = {};
    collectKey(menuItems, result);
    return result;
  }, [menuItems]);

  const arrayPathname = location.pathname.split('/');
  let key = '';
  arrayPathname.reverse().forEach((_, index) => {
    const keyPath = [...arrayPathname].splice(index, arrayPathname.length);
    if (searchParams.get('dontHighLight') === 'true') return key;
    const keyString = keyPath
      .reverse()
      .join('/')
      .replace('/', '') as keyof typeof patternKeys;

    if (patternKeys[keyString] && !key) key = patternKeys[keyString];
  });

  return key;
};