import { PrivateRoute } from 'src/components/PrivateRoute';
import { Route, Routes } from 'react-router-dom';
import { routes } from './routes';
import { Suspense, useContext, useEffect } from 'react';
import NotFound from 'src/pages/NotFound';
import { ProfileContext } from 'src/contexts/profile';

const AppRouter = () => {
  const { hasRole, fetchProfile } = useContext(ProfileContext);

  return (
    <Routes>
      <Route path="*" element={<NotFound />} />
      {routes
        // .filter((i) => !i.requirePermission || hasRole(i.requirePermission))
        .map(
          ({
            Component,
            isPrivate,
            path,
            children,
            Layout,
            role,
            requirePermission
          }) => {
            return (
              <Route key={path} element={Layout}>
                <Route
                  path={path}
                  element={
                    isPrivate ? (
                      <PrivateRoute
                        role={role}
                        requirePermission={requirePermission}
                      >
                        <Suspense fallback={null}>
                          <Component />
                        </Suspense>
                      </PrivateRoute>
                    ) : (
                      <Suspense fallback={null}>
                        <Component />
                      </Suspense>
                    )
                  }
                >
                  {children?.map(
                    (
                      {
                        path: childPath,
                        Component: ChildComponent,
                        props: childProps
                      },
                      childIndex
                    ) => (
                      <Route
                        key={childIndex}
                        path={childPath}
                        element={
                          isPrivate ? (
                            <PrivateRoute>
                              <Suspense fallback={null}>
                                <ChildComponent {...childProps} />
                              </Suspense>
                            </PrivateRoute>
                          ) : (
                            <Suspense fallback={null}>
                              <ChildComponent {...childProps} />
                            </Suspense>
                          )
                        }
                      />
                    )
                  )}
                </Route>
              </Route>
            );
          }
        )}
    </Routes>
  );
};

export default AppRouter;
