import MenuIconWrapper from 'src/components/MenuIconWrapper';

const GroupIcon = () => {
  return (
    <MenuIconWrapper>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 16.875C14.0711 16.875 15.75 15.1961 15.75 13.125C15.75 11.0539 14.0711 9.375 12 9.375C9.92893 9.375 8.25 11.0539 8.25 13.125C8.25 15.1961 9.92893 16.875 12 16.875Z"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M18.375 10.875C19.2485 10.8735 20.1103 11.0762 20.8916 11.4669C21.6729 11.8575 22.3521 12.4253 22.875 13.125"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.125 13.125C1.64794 12.4253 2.32714 11.8575 3.10843 11.4669C3.88972 11.0762 4.75149 10.8735 5.625 10.875"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6.60001 20.25C7.0939 19.2385 7.86196 18.3861 8.81667 17.7898C9.77139 17.1936 10.8744 16.8774 12 16.8774C13.1256 16.8774 14.2286 17.1936 15.1833 17.7898C16.1381 18.3861 16.9061 19.2385 17.4 20.25"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M5.62499 10.875C5.0556 10.8756 4.49779 10.7141 4.01676 10.4094C3.53572 10.1048 3.15133 9.6695 2.90848 9.15449C2.66563 8.63947 2.57437 8.06598 2.64535 7.50102C2.71633 6.93607 2.94663 6.40298 3.30932 5.96404C3.67202 5.52511 4.15215 5.19845 4.6936 5.02225C5.23505 4.84605 5.81546 4.82758 6.36702 4.96899C6.91858 5.11041 7.4185 5.40587 7.80838 5.82085C8.19825 6.23583 8.46198 6.7532 8.56874 7.3125"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M15.4312 7.3125C15.538 6.7532 15.8017 6.23583 16.1916 5.82085C16.5815 5.40587 17.0814 5.11041 17.633 4.96899C18.1845 4.82758 18.7649 4.84605 19.3064 5.02225C19.8478 5.19845 20.328 5.52511 20.6907 5.96404C21.0534 6.40298 21.2837 6.93607 21.3546 7.50102C21.4256 8.06598 21.3344 8.63947 21.0915 9.15449C20.8487 9.6695 20.4643 10.1048 19.9832 10.4094C19.5022 10.7141 18.9444 10.8756 18.375 10.875"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </MenuIconWrapper>
  );
};

export default GroupIcon;
