import { CSSProperties, HTMLAttributes, createContext, useState, Dispatch, SetStateAction } from 'react';
import { Button, Flex, Modal, ModalProps, styled } from 'core-ui';
import classNames from 'classnames';

interface ModalProviderPropsI {
  children: React.ReactNode;
}

interface ModalConfigI {
  title?: string;
  content?: React.ReactNode | string;
  okeButtonLabel?: string;
  cancelButtonLabel?: string;
  footer?: React.ReactNode;
  okeButtonStyle?: CSSProperties;
  modalProps?: ModalProps;
  onOke?: () => void;
  onCancel?: () => void;
  cancelButtonClassName?: string;
  okeButtonClassName?: string;
}

export const ModalContext = createContext({
  openModal: (_config?: ModalConfigI) => {},
  closeModal: () => {},
  setLoading: (loading: boolean) => {},
});

const defaultConfigs = {
  title: '',
  content: <></>,
  okeButtonLabel: '',
  cancelButtonLabel: '',
  footer: null,
  onOke: () => {},
  onCancel: () => {}
};

const ModalProvider = (props: ModalProviderPropsI) => {
  const { children } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState<ModalConfigI>(defaultConfigs);
  const [isLoading, setIsLoading] = useState(false)

  const openModal = (configs?: ModalConfigI) => {
    setModalConfig({ ...defaultConfigs, ...configs });
    setIsOpen(true);
  };

  const setLoading =(loading: boolean) => {
    setIsLoading(loading)
  }

  const closeModal = () => {
    setIsOpen(false);
  };

  const handleCancel = () => {
    closeModal();
    modalConfig?.onCancel?.();
  };

  return (
    <ModalContext.Provider value={{ openModal, closeModal, setLoading }}>
      {children}

      <Modal
        centered
        open={isOpen}
        onCancel={closeModal}
        footer={modalConfig?.footer}
        {...modalConfig.modalProps}
      >
        <div className="mb-[10px] justify-center flex">
          {/* <WarningIcon /> */}
        </div>

        <h2 className="text-center text-[#4758ff] mb-[8px]">
          {modalConfig?.title}
        </h2>

        <p>{modalConfig?.content}</p>

        <div className="flex px-[16px] gap-[10px] justify-center">
          <Button
            style={{
              width: '147px',
              height: '36px',
              fontSize: '16px'
            }}
            onClick={handleCancel}
            className={modalConfig?.cancelButtonClassName}
          >
            {modalConfig?.cancelButtonLabel}
          </Button>
          <Button
            style={{
              width: '147px',
              height: '36px',
              fontSize: '16px',
              ...modalConfig?.okeButtonStyle
            }}
            type="primary"
            onClick={modalConfig?.onOke}
            className={modalConfig?.okeButtonClassName}
            loading={isLoading}
          >
            {modalConfig?.okeButtonLabel}
          </Button>
        </div>
      </Modal>
    </ModalContext.Provider>
  );
};

export default ModalProvider;
