import { Form, styled } from 'core-ui';
interface StyledFormProps {
  isHiddenUploadSelect?: boolean;
}

const StyledForm = styled(Form)<StyledFormProps>`
  .ant-form-item-label {
    font-weight: bold;
  }
  .ant-upload-list-picture-card {
    flex-wrap: nowrap !important;
    overflow-x: auto;
    position: relative;

    .ant-upload-list-item-container {
      .ant-upload-list-item {
        min-width: 102px;
      }
    }

    .ant-upload-select {
      position: sticky;
      right: 0;
      z-index: 100;
      background-color: #fff !important;
      .ant-upload {
        min-width: 102px;
      }
      ${({ isHiddenUploadSelect  }) =>
        isHiddenUploadSelect  &&
        `
        display: none;
      `}
    }
  }
`;
export default StyledForm;
