import { AgreementConfirmStatus } from 'src/enums/agreementStatus';
import { ApiPath } from 'src/enums/apiPath';
import { RepairDocumentStatus } from 'src/enums/documentStatus';
import { customerApiRequest } from 'src/utils/api';

export const getAgreementList = async (params?: { [key: string]: any }) => {
  const res = await customerApiRequest.get({
    url: ApiPath.CUSTOMER_AGREEMENTS,
    params: params
  });

  return res.data;
};

export const getAgreementDetail = async (id: string) => {
  const res = await customerApiRequest.get({
    url: ApiPath.CUSTOMER_AGREEMENTS + `/${id}`
  });

  return res.data;
};

export const getCustomerDocumentDetail = async (
  repairId: string | number,
  documentId: string | number
) => {
  const response = await customerApiRequest.get({
    url:
      ApiPath.CUSTOMER_REPAIRS +
      `/${repairId}` +
      ApiPath.DOCUMENTS +
      `/${documentId}`
  });

  return response.data;
};

export const updateCustomerDocumentStatus = async (
  repairId: string | number,
  documentId: string | number,
  body: { status: RepairDocumentStatus }
) => {
  const res = await customerApiRequest.put({
    url:
      ApiPath.CUSTOMER_REPAIRS +
      `/${repairId}` +
      ApiPath.DOCUMENTS +
      `/${documentId}`,
    data: body,
    headers: {
      'accept-language': 'ja'
    }
  });

  return res.data;
};

export const customerConfirmSubmitAgreement = async (
  agreementId: string | number,
  repairId: string | number,
  data: any
) => {
  const res = await customerApiRequest.put({
    url:
      ApiPath.CUSTOMER_AGREEMENTS +
      `/${agreementId}` +
      ApiPath.REPAIR +
      `/${repairId}`,
    data,
    headers: { 'Content-Type': 'multipart/form-data', 'accept-language': 'ja' }
  });

  return res.data;
};

export const customerUpdateAgreementConfirmStatus = async (
  id: string | number,
  status: AgreementConfirmStatus
) => {
  const response = await customerApiRequest.put({
    url: ApiPath.CUSTOMER_AGREEMENTS + `/${id}` + ApiPath.STATUS,
    data: {
      status
    }
  });

  return response.data;
};

export const customerSendConfirmToAdmin = async (
  agreementId: string | number,
  repairId: string | number
) => {
  const res = await customerApiRequest.put({
    url:
      ApiPath.CUSTOMER_AGREEMENTS +
      `/${agreementId}` +
      ApiPath.REPAIR +
      `/${repairId}` +
      ApiPath.CONFIRM,
    headers: {
      'accept-language': 'ja'
    }
  });

  return res.data;
};

export const getNotReadAgreementCount = async () => {
  const res = await customerApiRequest.get({
    url: ApiPath.CUSTOMER_NOT_READ_AGREEMENT_COUNT
  });

  return res.data;
};
